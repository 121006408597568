import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SocialShareSection from '../SocialShareSection/SocialShareSection'
import EpaSeal from '../../Shared/svg/EpaSeal'
import Globe from '../../Shared/svg/Globe'
import MercyCorps from '../../Shared/svg/MercyCorps'
import Noaa from '../../Shared/svg/Noaa'
import EyeOpenedNoBg from '../../../images/eye-opened-main-page.svg'
import {
  PageWrapper,
  PageTitleWrapper,
  PageTitle,
  EyeBackground,
  SourcesWrapper,
  SourcesCol,
  SourcesBox,
  SourcesBoxLogoWrapper,
  SourcesBoxLogo,
  SourcesBoxTextWrapper,
  SourcesBoxTitle,
  SourcesBoxText,
  PageName,
  Container
} from './styled'

import sourcesData from './sourcesData'

const SourcesPageWrapper = () => {
  const fluidImages = useStaticQuery(graphql`
    query getImagesSources {
      allFile(
        filter: {
          relativePath: {
            regex: "/(nasa.png|c2es.png|green-peace.png|nature-conservancy.png|sustainability-for-all.png|wwf.png)/"
          }
        }
      ) {
        nodes {
          relativePath
          childImageSharp {
            fluid(jpegProgressive: true) {
              aspectRatio
              originalImg
              originalName
              presentationHeight
              presentationWidth
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
  `)

  const getFluidImageData = name => {
    const node = fluidImages.allFile.nodes.find(node =>
      node.relativePath.includes(name)
    )
    return node ? node.childImageSharp.fluid : null
  }

  const getIconSVG = name => {
    switch (name) {
      case 'epa-seal':
        return <EpaSeal />
      case 'globe':
        return <Globe />
      case 'mercy-corps':
        return <MercyCorps />
      case 'noaa':
        return <Noaa />
      default:
        return null
    }
  }

  const getIcon = name => {
    const fluidImageData = getFluidImageData(name)
    if (fluidImageData) {
      let smallerWidth = null
      let marginTop = null
      let isPanda = false
      let isNasa = false
      switch (name) {
        case 'wwf':
          smallerWidth = 95
          marginTop = 10
          isPanda = true
          break
        case 'c2es':
          smallerWidth = 125
          break
        case 'nasa':
          smallerWidth = 170
          isNasa = true
          break
        case 'green-peace':
        case 'nature-conservancy':
        case 'sustainability':
          marginTop = 10
          break
        default:
          break
      }
      return (
        <SourcesBoxLogo
          fluid={fluidImageData}
          alt={name}
          smallerWidth={smallerWidth}
          marginTop={marginTop}
          isPanda={isPanda}
          isNasa={isNasa}
        />
      )
    }
    const iconSVG = getIconSVG(name)
    if (iconSVG) {
      return iconSVG
    }
    return <p>Icon not found</p>
  }

  const sourcesDataFirstCol = sourcesData.filter(
    (_, i, arr) => i < arr.length / 2
  )
  const sourcesDataSecondCol = sourcesData.filter(
    (_, i, arr) => i >= arr.length / 2
  )

  return (
    <PageWrapper>
      <Container>
        <PageName>Sources</PageName>
        <PageTitleWrapper>
          <PageTitle>
            We get the facts from here <EyeBackground src={EyeOpenedNoBg} />
          </PageTitle>
        </PageTitleWrapper>
      </Container>

      <SourcesWrapper>
        <SourcesCol>
          {sourcesDataFirstCol.map(({ icon, link, title, text }) => (
            <SourcesBox key={icon}>
              <SourcesBoxLogoWrapper href={link} target='_blank'>
                {getIcon(icon)}
              </SourcesBoxLogoWrapper>
              <SourcesBoxTextWrapper>
                <SourcesBoxTitle>{title}</SourcesBoxTitle>
                <SourcesBoxText>{text}</SourcesBoxText>
              </SourcesBoxTextWrapper>
            </SourcesBox>
          ))}
        </SourcesCol>

        <SourcesCol>
          {sourcesDataSecondCol.map(({ icon, link, title, text }) => (
            <SourcesBox key={icon}>
              <SourcesBoxLogoWrapper href={link} target='_blank'>
                {getIcon(icon)}
              </SourcesBoxLogoWrapper>
              <SourcesBoxTextWrapper>
                <SourcesBoxTitle>{title}</SourcesBoxTitle>
                <SourcesBoxText>{text}</SourcesBoxText>
              </SourcesBoxTextWrapper>
            </SourcesBox>
          ))}
        </SourcesCol>
      </SourcesWrapper>

      <SocialShareSection />
    </PageWrapper>
  )
}

export default SourcesPageWrapper
