import React from 'react'

import MainLayout from '../components/LayoutsRelated/Layouts/MainLayout'
import SourcesPageWrapper from '../components/PageRelated/SourcesPageWrapper/SourcesPageWrapper'

const Sources = () => (
  <MainLayout isPage>
    <SourcesPageWrapper />
  </MainLayout>
)
export default Sources
