export default [
  {
    icon: 'nasa',
    link: 'https://climate.nasa.gov/',
    title: 'Global climate change',
    text:
      'The National Aeronautics and Space Administration (NASA) is an independent agency of the United States Federal Government responsible for the civilian space program, as well as aeronautics and space research.'
  },
  {
    icon: 'green-peace',
    link: 'https://www.greenpeace.org/international/',
    title: 'Greenpeace',
    text:
      'Greenpeace states its goal is to "ensure the ability of the Earth to nurture life in all its diversity" and focuses its campaigning on worldwide issues such as climate change, deforestation, overfishing, commercial whaling, genetic engineering, and anti-nuclear issues. '
  },
  {
    icon: 'sustainability',
    link: 'https://www.activesustainability.com/',
    title: 'Sustainability',
    text:
      'Sustainability is understood as the development that meets the present needs without compromising the capacities of future generations, ensuring the balance between economic growth, environmental care and social welfare.'
  },
  {
    icon: 'noaa',
    link: 'https://oceanservice.noaa.gov/',
    title: 'National Ocean Service',
    text:
      "NOAA's National Ocean Service is the nation's premier federal science agency for our ocean and coasts. The National Ocean Service (NOS) is on the front lines in understanding, predicting, and responding to the challenges facing our ocean and coasts."
  },
  {
    icon: 'c2es',
    link: 'https://www.c2es.org/',
    title: 'C2ES',
    text:
      'Center for Climate and Energy Solutions (C2ES) – an independent, nonpartisan, nonprofit organization working to forge practical solutions to climate change.'
  },
  {
    icon: 'wwf',
    link: 'https://www.worldwildlife.org/',
    title: 'World Wildlife Fund',
    text:
      'The World Wide Fund for Nature (WWF) is an international non-governmental organization founded in 1961, working in the field of wilderness preservation, and the reduction of human impact on the environment.'
  },
  {
    icon: 'nature-conservancy',
    link: 'https://www.nature.org/en-us/',
    title: 'The Nature Conservancy',
    text:
      'The Nature Conservancy is a charitable environmental organization. The mission of The Nature Conservancy is to "protect animals, plants and natural communities that represent the diversity of life on Earth by protecting the lands and waters they need to survive.'
  },
  {
    icon: 'mercy-corps',
    link: 'https://www.mercycorps.org/',
    title: 'Mercy Corps',
    text:
      'Mercy Corps is a global team of humanitarians working together on the front lines of crisis, disaster, poverty and climate change to create a world where everyone can prosper. '
  },
  {
    icon: 'epa-seal',
    link: 'https://www.epa.gov/',
    title: 'Environmental Protection Agency',
    text:
      'The Environmental Protection Agency (EPA) is an independent agency, specifically an independent executive agency, of the United States federal government for environmental protection.'
  },
  {
    icon: 'globe',
    link: 'https://nca2014.globalchange.gov/',
    title: 'National Climate Assessment',
    text:
      'The National Climate Assessment summarizes the impacts of climate change on the United States, now and in the future.'
  }
]
