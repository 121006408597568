import styled, { keyframes, css } from 'styled-components'
import Img from 'gatsby-image'

import {
  PageWrapperStyle,
  PageTitleWrapperStyle,
  PageTitleStyle,
  PageNameComponent
} from '../../../styled/pageStyle'

import { siteBreakPoints, colors } from '../../../consts/styleConsts'

const { grey } = colors.grey
const { primary } = colors
const { small } = siteBreakPoints
const container = 61.81
const sourcesWidth = 72.72
const width1000 = 1000
const width1300 = 1300

const FadeInLeft = keyframes`
  0% {opacity: 0; transform: translateX(-30px)}
  50% {transform: translateX(0)}
  100% {opacity: 1; transform: translateX(0)}
`
const FadeInBottom = keyframes`
  0% {opacity: 0; transform: translateY(30px)}
  50% {transform: translateY(0)}
  100% {opacity: 1; transform: translateY(0)}
`
const Scale = keyframes`
  0% {opacity: 0; transform: translate(-50%, -50%) scale(0)}
  60% {opacity: 0; transform: translate(-50%, -50%) scale(0.9)}
  100% {opacity: 0.13; transform: translate(-50%, -50%) scale(1)}
`

export const PageWrapper = styled(PageWrapperStyle)``
export const Container = styled.div`
  width: ${container}rem;
  margin: 0 auto;
  @media screen and (max-width: ${width1300}px) {
    width: 100%;
    padding: 0 1.25rem;
  }
`
export const PageName = styled(PageNameComponent)`
  pdding-left: 1.25rem;
  opacity: 0;
  animation: ${FadeInLeft} 1.5s ease forwards 0.2s;
`
export const PageTitleWrapper = styled(PageTitleWrapperStyle)`
  display: flex;
  justify-content: center;
  text-align: center;
`
export const PageTitle = styled(PageTitleStyle)`
  max-width: 39.45rem;
  position: relative;
  opacity: 0;
  animation: ${FadeInBottom} 1.5s ease forwards 0.4s;
`
export const EyeBackground = styled.img`
  position: absolute;
  left: 50%;
  top: 25%;
  width: 34.09rem;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: ${Scale} 1.6s ease forwards;
  @media screen and (max-width: ${small}px) {
    width: calc(100% - 40px);
    height: auto;
  }
`
export const SourcesWrapper = styled.div`
  max-width: ${sourcesWidth}rem;
  margin: 0 auto;
  padding: 9.09rem 0.9rem;
  padding-bottom: 3.18rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 14.54rem;
  opacity: 0;
  position: relative;
  animation: ${FadeInBottom} 1.5s ease forwards 0.8s;
  &:before {
    content: '';
    position: absolute;
    left: 20px;
    bottom: 0;
    height: 2px;
    width: 200%;
    background: ${primary};
  }
  @media screen and (max-width: ${width1000}px) {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
  @media screen and (max-width: ${small}px) {
    padding-top: 6.25rem;
    padding-right: 0;
    margin-bottom: 3.62rem;
    padding-bottom: 2.81rem;
  }
`
export const SourcesCol = styled.div`
  max-width: 32.27rem;
`
export const SourcesBox = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  margin-bottom: 5.9rem;
  @media screen and (max-width: ${width1000}px) {
    height: auto;
  }
  @media screen and (max-width: ${small}px) {
    margin-bottom: 3.12rem;
  }
`
export const SourcesBoxLogoWrapper = styled.a`
  display: block;
  cursor: pointer;
  width: 30%;
  text-align: center;
  svg {
    width: 100%;
  }
  @media screen and (max-width: ${small}px) {
    width: 20%;
    text-align: left;
    svg {
      width: 80%;
      position: relative;
      transform: translateY(-25%);
    }
  }
`
export const SourcesBoxLogo = styled(Img)`
  width: 100%;
  ${({ smallerWidth }) =>
    smallerWidth &&
    css`
      width: ${smallerWidth}px;
      margin: 0 auto;
    `}
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}
  @media screen and (max-width: ${small}px) {
    width: 80%;
    margin-left: 0;
    ${({ isNasa }) =>
      isNasa &&
      css`
        width: 100%;
      `}
    ${({ isPanda }) =>
      isPanda &&
      css`
        width: 70%;
      `}
  }
`
export const SourcesBoxTextWrapper = styled.div`
  width: 70%;
  padding: 0 1.25rem;
  @media screen and (max-width: ${small}px) {
    padding-left: 0;
    width: 80%;
  }
`
export const SourcesBoxTitle = styled.span`
  font-family: Plex-Bold;
  text-transform: uppercase;
  color: ${grey};
  font-size: 1.27rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.68remrem;
  display: block;
  margin-bottom: 1.36rem;
  @media screen and (max-width: ${small}px) {
    font-size: 1.25rem;
    line-height: 1.56rem;
  }
`
export const SourcesBoxText = styled.span`
  color: ${grey};
  font-family: Plex-Light;
  font-size: 0.81rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.04rem;
  @media screen and (max-width: ${small}px) {
    font-size: 0.87rem;
    line-height: 1.12rem;
  }
`
